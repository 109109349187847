<template>
  <div>
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="订单类型" prop="orderType">
          <el-select class="item-choose" @change="getDataList" v-model="dataForm.orderType" style="width: 100%;" filterable clearable placeholder="请选择">
            <el-option v-for="item in dictTypeMap.orderType" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户名称">
          <el-input v-model="dataForm.customerName" placeholder="请输入客户名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="请输入车牌号" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货时间">
          <el-date-picker
            v-model="dataForm.takeGoodsTime"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            @change="getDataList"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
<!--        <el-form-item label="进仓单号">-->
<!--          <el-input v-model="dataForm.inWarehouseNo" placeholder="请输入进仓单号" clearable></el-input>-->
<!--        </el-form-item>-->
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList" height="65vh" border size="small"
                :span-method="arraySpanMethod" :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight"
                v-loading="dataListLoading" style="width: 100%;">
        <el-table-column  label="客户名称" prop="orderInfo.customerName" align="left" width="100" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  label="客户联系人" prop="orderInfo.customerContactName" align="left" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货时间"  width="105" :show-overflow-tooltip="true" prop="orderInfo.takeGoodsDate">
          <template slot-scope="scope">
            {{ scope.row.orderInfo.takeGoodsDate + ' ' + scope.row.orderInfo.takeGoodsTime}}
          </template>
        </el-table-column>
        <el-table-column label="订单类型" :formatter="orderType" prop="orderInfo.orderType"  width="60" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="所需车型" :formatter="dictConvertVehicle" prop="orderInfo.vehicleType" align="center" width="80" :show-overflow-tooltip="true">
        </el-table-column>
<!--        <el-table-column label="指派车型" prop="vehiclePlan.vehicleTypeName" align="center" width="80" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
        <el-table-column label="车牌号" prop="vehiclePlan.vehiclePlate" align="left" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="报关方式" :formatter="entranceType" prop="orderInfo.entranceType" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  label="提货方"  prop="factoryName" align="left" width="75" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  label="提货地址"  prop="fullAddress" align="left" width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货联系人"  prop="contactName"  width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货联系电话" prop="contactPhone" align="left" width="85" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货注意事项" prop="takeRemark" align="left" width="85" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="卸货方" prop="giveAddressInfo.factoryName" width="75" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货地址" prop="giveAddressInfo.fullAddress" width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货联系人" prop="giveAddressInfo.contactName"  width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货电话" prop="giveAddressInfo.contactPhone" width="85" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="公里数(KM)" prop="orderInfo.distance" :formatter="getDistanceValue"  width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="件数" prop="orderGoods.pieceNum" width="40"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="体积" prop="orderGoods.volume" width="40"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="重量" prop="orderGoods.weight" width="40"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="货物尺寸(长/宽/高)"   width="110"  :show-overflow-tooltip="true"  prop="goods.length">
          <template slot-scope="scope">
            {{ scope.row.orderGoods.length }} / {{ scope.row.orderGoods.width }} / {{ scope.row.orderGoods.height }}
          </template>
        </el-table-column>
        <el-table-column label="包装类型" :formatter="manyDictConvert" width="80" :show-overflow-tooltip="true" prop="orderGoods.packType" align="left">
        </el-table-column>
        <el-table-column label="客户单号"  prop="orderInfo.customerSelfNo" width="70" :show-overflow-tooltip="true" align="left">
        </el-table-column>
        <el-table-column label="车次号" prop="orderInfo.planNo" width="110" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="订单号" prop="orderInfo.orderNo" width="105" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column  label="下单时间" prop="orderInfo.commitTime" align="left" width="120" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column  label="调度时间" prop="orderInfo.dispatchTime" align="left" width="120" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column  label="调度操作人" prop="orderInfo.dispatchName" width="70" :show-overflow-tooltip="true" align="left">
        </el-table-column>
        <el-table-column  label="出车时间" prop="outDriveTime" align="left" width="120" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column  label="到达时间" prop="arrivedTime" align="left" width="120" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column  label="装货完成时间" prop="loadGoodsTime" align="center" width="120" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column label="装货图片" width="70" align="center">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, loadGoodsTypeList)" style="color: blue;cursor:pointer">查看</span>
          </template>
        </el-table-column>
        <el-table-column label="卸货到达时间" prop="arrivedGiveTime" align="left" width="120">
        </el-table-column>
        <el-table-column label="卸货到达地点" prop="arrivedGiveAddress" align="left" width="160"
                         :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column fixed="right" header-align="center" align="center" width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="detail(scope.row, 'view')">详情</el-button>
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="updateWayBill(scope.row, successStatus)">已签收</el-button>
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="abnormalReporting(scope.row)">异常上报</el-button>
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="updateWayBill(scope.row, abnormalStatus)">异常结束</el-button>
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="trackView(scope.row)">轨迹查看</el-button>
<!--              <el-button type="text" v-if="scope.row.vehiclePlan.allWinVehicle === 1" size="small" @click="inputPrepayment(scope.row.orderInfo)">补录代垫</el-button>-->
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 异常结束弹框 -->
    <abnormal-stop-pop ref="abnormal"></abnormal-stop-pop>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto" :limit="1"></view-image>
    <report ref="report"></report>
    <!-- 查看轨迹 -->
    <iframe-track ref="track"></iframe-track>
    <input-prepayment-pop ref="inputPrepaymentPop"></input-prepayment-pop>
  </div>
</template>

<script>
import viewImage from '@/views/commonPop/viewImage-fy'
import abnormalStopPop from './detail/abnormalStopPop'
import report from './detail/abnormalReport'
import iframeTrack from '@/views/commonPop/iframe-track'
import inputPrepaymentPop from './detail/inputPrepaymentPop'
import { newAuth } from '@/utils'
import qs from 'qs'
export default {
  data () {
    return {
      dataForm: {
        orderType: null,
        status: 15,
        takeGoodsTime: null,
        customerName: null,
        inWarehouseNo: null,
        vehiclePlate: null,
        customerIdList: []
      },
      tabActive: '1',
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      loadGoodsTypeList: [6011],
      addOrUpdateVisible: false,
      detailVisible: false,
      successStatus: 8,
      getIndex: -1,
      abnormalStatus: 9
    }
  },
  components: {
    abnormalStopPop,
    viewImage,
    report,
    iframeTrack,
    inputPrepaymentPop
  },
  created () {
    // this.setDefaultDate()
  },
  activated () {
    if (!newAuth('order:manager:findAll')) {
      this.dataForm.customerIdList = this.currentUser.customerIdList
    }
    // this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    },
    currentUser: {
      get () {
        return this.$store.state.user.currentUser
      }
    }
  },
  methods: {
    // 异常上报
    abnormalReporting (row) {
      this.$refs.report.init(row)
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24)
      end.setTime(end.getTime() + 3600 * 1000 * 24)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    // 查看照片
    viewPhoto (row, typeList) {
      this.$refs.viewPhoto.init(row.orderInfo.id, typeList)
    },
    inputPrepayment (orderInfo) {
      this.$refs.inputPrepaymentPop.init(orderInfo)
    },
    // 轨迹查看
    trackView (row) {
      this.$http({
        url: this.$http.adornUrl(`/order/getToMapTrajectoryUrl/${row.orderInfo.id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        // window.open(data)
        this.$refs.track.init(data)
      }).catch(() => {})
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      let param = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'status': this.dataForm.status,
        'orderType': this.dataForm.orderType,
        'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
        'takeGoodsEndDate': timeParam.takeGoodsEndDate,
        'customerName': this.dataForm.customerName,
        'inWarehouseNo': this.dataForm.inWarehouseNo,
        'vehiclePlate': this.dataForm.vehiclePlate,
        'customerIdList': this.dataForm.customerIdList
      }
      const params = qs.stringify(param, { arrayFormat: 'repeat' })
      this.$http({
        url: this.$http.adornUrl('/order/findYesDispatchOrderList?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          this.getSpanArr(data.list)
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.$emit('getTotal')
        this.dataListLoading = false
      })
    },
    // 异常结束
    abnormalStop (row) {
      this.$refs.abnormal.init()
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (row, todo) {
      this.$router.push({
        name: 'carpoolEditDetail',
        params: {
          id: row.orderInfo.id,
          todo: todo
        }
      })
    },
    // 订单状态修改
    updateWayBill (row, status) {
      let title = ''
      if (status === this.successStatus) {
        title = '已签收'
      } else if (status === this.abnormalStatus) {
        title = '异常结束'
      }
      this.$confirm('你正在执行【' + title + '】操作，请确认是否继续执行', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let formData = {
          id: row.orderInfo.id,
          status: status,
          takeGoodsDate: row.orderInfo.takeGoodsDate,
          takeGoodsTime: row.orderInfo.takeGoodsTime
        }
        this.$http({
          url: this.$http.adornUrl(`/order/updateStatusById`),
          method: 'POST',
          data: this.$http.adornData(formData)
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
                this.$emit('getTotal')
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {

      })
    },
    getDistanceValue (row, cell, value) {
      return parseFloat((value * 0.001).toFixed(1))
    }
  }
}
</script>
